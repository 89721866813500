import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import NavMenu from './menu/nav-menu'
import Hamburger from './menu/hamburger.js'
import { Container, Grid, Hidden } from "@material-ui/core"

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem!important'
    },
  },
}));

const Header = (props) => {
  
  const classes = useStyles()

  return(
    <header
      style={{
        background: `linear-gradient(90deg, rgba(7,148,101,1) 0%, rgba(26,166,119,1) 86%, rgba(6,147,99,1) 98%)`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      > */}
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10} sm={10} md={4} > 
            <h1 className={classes.header} style={{ margin: 0, zIndex: 1000}}>
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: `none`,
                }}
              >
                {props.siteTitle}
              </Link>
            </h1>
            <span
              style={{
                color: 'white',
                fontSize: '14px',
                fontStyle: 'italic'
              }}
            >
              A Tech Blog for Everyone
            </span>
          </Grid>
          <Hidden only={['sm', 'xs']}>
            <Grid item md={8}>
              <NavMenu />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={2} sm={2} style={{textAlign: "right"}}> 
              <Hamburger toggleMenu={props.handleMenu} />
            </Grid>
          </Hidden>
        </Grid>
      {/* </div> */}
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
