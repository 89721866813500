import React from 'react'
import { Link } from 'gatsby'
import PropTypes from "prop-types"
import './nav-item.css'


const NavItem = (props) => {

  return (
    <li className='nav-menu_link'>
      <Link to={props.link} activeStyle={{background:'#178a63', borderRadius: '5px'}}>
        {props.text}
      </Link>
    </li>
  )
}

NavItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string
}

export default NavItem