const NAVITEMS = [
  {
    id: 1,
    text: 'Home',
    link: '/'
  },
  {
    id: 5,
    text: 'Work',
    link: '/work'
  },
  {
    id: 2,
    text: 'Blog',
    link: '/blog'
  },
  {
    id: 4,
    text: 'About',
    link: '/about'
  }
]

export default NAVITEMS