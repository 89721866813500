import PropTypes from "prop-types"
import React from "react"
import { Container, Grid } from "@material-ui/core"
import Wave from '../components/wave/wave'

const Footer = (props) => {
  

  return(
    <footer
      style={{
        background: `linear-gradient(90deg, rgba(7,148,101,1) 0%, rgba(26,166,119,1) 86%, rgba(6,147,99,1) 98%)`,
        height: '350px',
        marginTop: '5rem'
      }}
    >
      <Wave    
        style={{
          width: '100vw',
          height: '150px',
          transform: 'translateY(-40%) rotate(180deg)',
          marginBottom: '0',
        }} 
      />

      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems="center">
            <span
              style={{
                color: 'white',
                fontSize: '14px',
                fontStyle: 'italic'
              }}
            >
              A Tech Blog for Everyone
            </span>
          </Grid>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
