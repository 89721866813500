import React from 'react'
import WaveLogo from '../wave.svg'

const Wave = (props) => (
  <img
    style={props.style}
    alt="Wave Presentation"
    src={WaveLogo}
  />
)

export default Wave