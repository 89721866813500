import React from 'react'
import NavItem from './nav-item'
import './nav-menu.css'
import NAVITEMS from './nav-items'

const NavMenu = () => {

  console.log(NAVITEMS);
  
  const menuItems = NAVITEMS.map(item => (<NavItem key={item.id} link={item.link} text={item.text} />))
  return (
  
    <ul className="nav-items_menu">
      { menuItems}
    </ul>
  )
}

export default NavMenu